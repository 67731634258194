/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getFileColumns = /* GraphQL */ `
  mutation GetFileColumns($fileId: ID!) {
    getFileColumns(fileId: $fileId) {
      value
    }
  }
`;
export const parseFile = /* GraphQL */ `
  mutation ParseFile($fileId: ID!, $columnIndex: Int!, $isHeader: Boolean!) {
    parseFile(fileId: $fileId, columnIndex: $columnIndex, isHeader: $isHeader) {
      fileId
      description
      payload
    }
  }
`;
export const addRealtimeEvent = /* GraphQL */ `
  mutation AddRealtimeEvent(
    $fileId: ID!
    $description: String
    $payload: String
  ) {
    addRealtimeEvent(
      fileId: $fileId
      description: $description
      payload: $payload
    ) {
      fileId
      description
      payload
    }
  }
`;
