import { createEvent, createStore } from "effector-logger";

import { Column } from "../API";

export interface genderStore {
    state: string;
    fileId?: string;
    firstNameField?: number;
    columnsData?: Column[];
    columnIndex?: number;
    isHeader: boolean;
    completedFile?: string;
    percentage: string;
}

export const emptyGenderStore: genderStore = {
    state: "INIT",
    isHeader: true,
    percentage: "0",
};

export const updateGenderStore = createEvent<Partial<genderStore>>();

export const resetGender = createEvent();

export default createStore<genderStore>(emptyGenderStore)
    .on(updateGenderStore, (state, s) => {
        return {
            ...state,
            ...s,
        };
    })
    .on(resetGender, (state) => {
        return {
            ...emptyGenderStore,
        };
    });
