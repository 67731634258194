/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const subscribeToAddRealtimeEvent = /* GraphQL */ `
  subscription SubscribeToAddRealtimeEvent {
    subscribeToAddRealtimeEvent {
      fileId
      description
      payload
    }
  }
`;
